.RichEditor-root {
    background: #fff;
    border: 1px solid #D3D3D3;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
    border-radius: 4px;
}

.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
    position: "relative";
}

.RichEditor-root:hover {
    border: 1px solid #000;
}

.RichEditor-root:focus-within {
    border: 2px solid #000;
}


.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #5890ff;
}

.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

.headlineButtonWrapper {
    display: inline-block;
}

.headlineButton {
    background: #fbfbfb;
    font-size: 18px;
    border: 0;
    vertical-align: bottom;
    height: 30px;
    width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
    background: #f3f3f3;
}

.wrapperButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrapperButton>div>button {
    background-color: #fefefe;
    border: none;
    font-size: 14px;
    color: #A5A4A4;
    cursor: pointer;
}

.wrapperButton>div>button>svg {
    height: 18px;
    filter: invert(70%) sepia(4%) saturate(8%) hue-rotate(314deg) brightness(93%) contrast(93%)
}