@font-face {
  font-family: plexSans;
  src: url("./Assets/Fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: plexSansLight;
  src: url("./Assets/Fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf");
}

@font-face {
  font-family: plexSansSBold;
  src: url("./Assets/Fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: DellaRespira;
  src: url("./Assets/Fonts/IBM_Plex_Sans/DellaRespira-Regular.ttf");
}

@font-face {
  font-family: ElMessiri;
  src: url("./Assets/Fonts/El_Messiri/ElMessiri-Regular.ttf");
}

@font-face {
  font-family: ElMessiriBold;
  src: url("./Assets/Fonts/El_Messiri/ElMessiri-Bold.ttf");
}

@font-face {
  font-family: ElMessiriMedium;
  src: url("./Assets/Fonts/El_Messiri/ElMessiri-Medium.ttf");
}

@font-face {
  font-family: ElMessiriSemiBold;
  src: url("./Assets/Fonts/El_Messiri/ElMessiri-SemiBold.ttf");
}

@font-face {
  font-family: Raleway;
  src: url("./Assets/Fonts/Raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: RalewayMedium;
  src: url("./Assets/Fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: RalewaySemiBold;
  src: url("./Assets/Fonts/Raleway/Raleway-SemiBold.ttf");
}

body {
  margin: 0;
  font-family: DellaRespira, plexSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.7;
  visibility: visible;
  z-index: -10;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  backdrop-filter: grayscale(1);
}